.agora-ellipsis {
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;

    &.agora-hover:hover {
        position: relative;
        white-space: unset;
        word-wrap: break-word;
    }
}

.invalid-label {
    color: lighten($color_danger, 15%);
}

*::-webkit-scrollbar {
    width: 5px;
    height: 5px;
}

*::-webkit-scrollbar-thumb {
    background: $color_grey_4;
    border-radius: 6px;
}

.rui-night-mode *::-webkit-scrollbar-thumb {
    background: $color_dark_4;
}

.menu-script-list {
    height: calc(100vh - 330px);
    min-height: 502px;
}

.menu-resize-pane {
    height: calc(100vh - 330px);
    min-height: 502px;

    & .split-view {
        overflow-y: unset !important;
    }

    // & .card {
    //    height: calc(100% - 22px);
    // }
}

.rui-scrollbar.rui-filemanager-content {
    padding-right: 15px;
    padding-left: 15px;
}


.btn-light.disabled,
.btn-light:disabled {
    color: $color_grey_5 !important;
}

.btn-custom-round:disabled::after {
    width: 0 !important;
}

.rui-night-mode {

    .btn-light.disabled,
    .btn-light:disabled {
        background-color: unset;
        color: $color_dark_5 !important;
    }

    .form-control:disabled,
    .form-control[readonly] {
        background: $color_dark_3;
    }

}

.agora-link {
    color: var(--agora-brand, $color_brand);
}

.agora-pointer {
    cursor: pointer;
}


.nav-profile img {
    border-radius: 100%;
    width: 120px;
    margin-bottom: 10px;
}

.rc-slider-disabled {
    background-color: transparent !important;

    &>.rc-slider-rail {
        background-color: $color_grey_3;
    }
}

.rui-night-mode {
    .rc-slider-disabled {
        &>.rc-slider-rail {
            background-color: $color_dark_7;
        }
    }
}

.agora-feedback {

    &.chat {
        width: 16px;
        height: 16px;
    }

    &:not(.chat) {
        width: 32px;
        height: 22px;
        padding: 4px;
    }

    &.like {
        color: $color_success;
    }

    &.dislike {
        color: $color_danger;
    }
}


/*=============================================
=           OVERRIDE BOOTSTRAP BRAND          =
=============================================*/

:root {
    --agora-brand: #15C5A8;
    --agora-brand-dk-7-5: #11a28b;
    --agora-brand-dk-12-5: #0f8b77;
    --agora-brand-dk-05: #13ae94;
    --agora-brand-dk-10: #109781;
    --agora-brand-dk-20: #0b6959;
    --agora-brand-lg-05: #17dcbc;
    --agora-brand-lg-30: #81f2df;
    --agora-brand-r: 21;
    --agora-brand-g: 197;
    --agora-brand-b: 168;
    --agora-grey: #eaecf0;
    --agora-night-grey: #454c55;
}


.btn-brand {
    border-color: var(--agora-brand, $color_brand);

    &:not(.btn-outline) {
        background-color: var(--agora-brand, $color_brand);
    }

    &:hover {
        background-color: var(--agora-brand-dk-7-5);
        border-color: var(--agora-brand-dk-10);
    }

    &:focus,
    &.focus {
        background-color: var(--agora-brand-dk-7-5);
        border-color: var(--agora-brand-dk-10);
        box-shadow: 0 0 0 0.2rem rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: 0.5);
    }

}

.btn-outline-brand {
    color: var(--agora-brand, $color_brand);
    border-color: var(--agora-brand, $color_brand);
}




.btn-outline-brand:hover,
.btn-brand.disabled,
.btn-brand:disabled,
.btn-outline-brand:not(:disabled):not(.disabled):active,
.btn-outline-brand:not(:disabled):not(.disabled).active,
.show>.btn-outline-brand.dropdown-toggle {
    background-color: var(--agora-brand, $color_brand);
    border-color: var(--agora-brand, $color_brand);
}

.btn-brand:not(:disabled):not(.disabled):active,
.btn-brand:not(:disabled):not(.disabled).active,
.show>.btn-brand.dropdown-toggle {
    background-color: var(--agora-brand-dk-7-5);
    border-color: var(--agora-brand-dk-10);

    &:focus {
        box-shadow: 0 0 0 0.2rem rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: 0.5);
    }
}

.badge-brand {
    background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: 0.8);
}

.alert-brand,
html.rui-night-mode .alert-brand {
    border-color: var(--agora-brand) !important;
}

.custom-checkbox .custom-control-input:not(:disabled):active~.custom-control-label::before,
.custom-radio .custom-control-input:not(:disabled):active~.custom-control-label::before,
.custom-switch .custom-control-input:not(:disabled):active~.custom-control-label::before {
    background-color: var(--agora-brand-lg-30) !important;
    border-color: var(--agora-brand-lg-30) !important;
}

.custom-checkbox .custom-control-input:disabled:checked~.custom-control-label::before,
.custom-switch .custom-control-input:disabled:checked~.custom-control-label::before {
    background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: 0.5);
}

.btn-outline-brand:not(:disabled):not(.disabled):active:focus,
.btn-outline-brand:not(:disabled):not(.disabled).active:focus,
.show>.btn-outline-brand.dropdown-toggle:focus {
    box-shadow: 0 0 0 0.2rem rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: 0.5);
}

/*=======  OVERRIDE BOOTSTRAP BRAND  ========*/