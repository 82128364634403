@import "../../../../common-assets/css/variables";

.agora-btn-check {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none;
    & + label {
        color: $color_dark_5!important;
        transition: 0.5s;
        text-transform: unset;
    }
    & + label>.rui-icon {
        width: 24px;
        height: 24px;
        margin-right: 20px;
    }

    &:hover:disabled:not(:checked) + label {
        background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .0)!important;
        &>.rui-icon {
            color: unset;// var(--agora-brand, $color_brand);
        }
    }

    &:hover:not(:checked) + label {
        background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .3)!important;
        &>.rui-icon {
            color: var(--agora-brand, $color_brand);
        }
    }

    &:active + label,
    &:checked + label {
        transform: scale(1.02, 1.02);
        background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .2)!important;
        &>.rui-icon {
            color: var(--agora-brand, $color_brand);
        }
    }
}

html.rui-night-mode {
    .agora-btn-check {
        & + label {
            color: $color_grey_6!important;
        }
    }
}
