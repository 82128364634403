.pagination {
    margin: -5px;
}
.page-item {
    margin: 5px;

    &.disabled .page-link {
        color: $color_grey_4;
        background-color: $color_grey_1;
    }
    &.active .page-link {
        background-color: var(--agora-brand, $color_brand);
    }
}
.page-link {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    min-width: 35px;
    padding: 11.5px 14px;
    margin: 0;
    font-size: 10px;
    font-weight: 700;
    color: $color_grey_6;
    text-transform: uppercase;
    background-color: $color_grey_1;
    border: 0;
    border-radius: .25rem;
    transition: background-color .15s ease-in-out, color .15s ease-in-out;

    .rui-icon {
        margin: -2px -3px;
    }

    &:hover,
    &.hover {
        color: $color_secondary;
        background-color: $color_grey_2;
    }
    &:focus,
    &.focus {
        color: $color_secondary;
        background-color: $color_grey_2;
        box-shadow: none;
    }
    &:active {
        background-color: $color_grey_3;
    }
}
// Sizing
.pagination-lg {
    .page-link {
        min-width: 41px;
        padding: 11px 12px;
        font-size: 13px;
        border-radius: .3rem;

        > .rui-icon {
            width: 18px;
            height: 18px;
            margin-top: .5px;
            margin-bottom: .5px;
        }
    }
}
.pagination-sm {
    .page-link {
        min-width: 31px;
        padding: 9px 12px;
        font-size: 9px;
        border-radius: .2rem;

        > .rui-icon {
            width: 14px;
            height: 14px;
            margin: -.5px -4px;
        }
    }
}
