// .rdt_Table {
// }
.rdt_TableRow {
    padding-top: 5px;
    border: none!important;
}
.rdt_TableCol {
    font-weight: 400;
    color: $color_grey_5;
    font-size: 13px!important;
    line-height: 23.4px!important;
}
.rdt_TableCol_Sortable {
    color: $color_secondary;
    font-size: 13px!important;
    line-height: 23.4px!important;
}
.rdt_TableCell {
    display: block;
    padding: 15px 10px!important;
    background-color: $color_grey_1;
    color: $color_secondary;
    line-height: 23.4px!important;
}
// .rdt_TableHeader {
// }
// .rdt_TableFooter {
// }
.rdt_TableHead {
    cursor: pointer;
    transition: color .15s ease-in-out;
}
.rdt_TableHeadRow {
    border-top: 1px solid #dee2e6;
    & div {
        padding-left: 0;
    }
    & [role="columnheader"] {
        padding: 20px 10px 10px;
        color: $color_text_2!important;
        font-weight: 600;
    }
}

.grmevn {
    max-height: calc(100vh - 330px)!important;
}
/*
.rdt_TableBody {

}
.rdt_ExpanderRow {

}
*/
.rdt_Pagination {
    margin-top: 10px;
}
