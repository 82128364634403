.swal2-container.swal2-backdrop-show {
    background-color: rgba($color_dark_2, .9);
}
.swal2-popup {
    box-shadow: 0 3px 10px rgba(0, 0, 0, .03);
    z-index: 1;

    .swal2-title {
        color: $color_text_1;
        font-weight: unset;
        font-size: 1.538rem;
    }
    .swal2-type {
        text-transform: capitalize;
    }
    .swal2-html-container,
    .swal2-content {
        font-size: 1rem;
        color: $color_text_2;
    }
    .swal2-styled {
        min-width: 100px;

        &.swal2-confirm {
            font-size: 13px;
            background-color: var(--agora-brand, $color_brand);
            border-radius: .25rem;
            transition: background-color .1s ease-in-out, box-shadow .1s ease-in-out;

            &:hover {
                background-color: var(--agora-brand-dk-10);
            }
            &:focus {
                box-shadow: 0 0 0 .2rem rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .5);
            }
        }
    }
    .swal2-actions:not(.swal2-loading) .swal2-styled:hover {
        background-image: none;
    }
}
@each $name, $color in $colors_alerts {
    .swal2-icon.swal2-#{$name} {
        color: $color;
        border-color: rgba($color, .3);

        [class^=swal2-#{$name}-line] {
            background-color: $color;
        }
        .swal2-success-ring {
            border-color: rgba($color, .3);
        }
    }
    .swal2-icon.swal2-error {
        border-color: rgba($color_danger, .3);

        [class^="swal2-x-mark-line"] {
            background-color: $color_danger;
        }
        .swal2-success-ring {
            border-color: rgba($color_danger, .3);
        }
    }
}
