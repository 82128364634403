.agora__option--is-focused {
    background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .2) !important;
}

.agora__option--is-selected {
    background-color: var(--agora-brand, $color_brand) !important;
}

.agora__option {
    &:active {
        background-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .9) !important;
    }
}

.agora__control--is-focused {
    &:hover {
        border-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .6) !important;
    }

    border-color: rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .6) !important;
    box-shadow: 0 0 0 0.2rem rgba(var(--agora-brand-r), var(--agora-brand-g), var(--agora-brand-b), $alpha: .2) !important;
}

.agora__control {
    border-color: $color_grey_3 !important;

    &:hover {
        border-color: $color_grey_3 !important;
    }

    box-shadow: '' !important;
}

.agora__single-value{
    z-index: 2 !important;
}

.agora__menu {
    z-index: 3 !important;
}

.rui-night-mode .agora__menu *:not(.agora__option) {
    background-color: $color_dark_3!important;
    color: $color_grey_5 !important;
}


.rui-night-mode .agora__control * {
    background-color: $color_dark_1!important;
    color: $color_grey_5 !important;
}

.rui-night-mode .agora__control {
    border-color: $color_dark_2 !important;
    background-color: $color_separator_dark !important;
}


.rui-night-mode .agora__option--is-selected {
    color: $color_grey_2 !important;
}
