/*!
 * Bootstrap (https://getbootstrap.com/)
 * Licensed under MIT (https://github.com/twbs/bootstrap/blob/master/LICENSE)
 */

@import "~bootstrap/scss/functions";

// stylelint-disable
$primary:   #007bff;
$secondary: #6c757d;
$success:   #2fc787;
$info:      #32bfc8;
$warning:   #fdbf21;
$danger:    #ef5164;
$light:     #f8f9fa;
$dark:      #393f49;
// stylelint-enable

@import "~bootstrap/scss/variables";

$brand:     #15C5A8 !default;
$brand2:    #0851B3 !default;
$brand3:    lighten(#000, 90%)!default;
// stylelint-disable
$theme-colors: map-merge(( "brand": $brand ), $theme-colors);
$theme-colors: map-merge(( "brand2": $brand2,"brand3": $brand3 ), $theme-colors);


$breadcrumb-divider: none;
// stylelint-enable

@import "~bootstrap/scss/mixins";
@import "~bootstrap/scss/root";
@import "~bootstrap/scss/reboot";
@import "~bootstrap/scss/type";
@import "~bootstrap/scss/images";
@import "~bootstrap/scss/code";
@import "~bootstrap/scss/grid";
@import "~bootstrap/scss/tables";
@import "~bootstrap/scss/forms";
@import "~bootstrap/scss/buttons";
@import "~bootstrap/scss/transitions";
@import "~bootstrap/scss/dropdown";
@import "~bootstrap/scss/button-group";
@import "~bootstrap/scss/input-group";
@import "~bootstrap/scss/custom-forms";
@import "~bootstrap/scss/nav";
@import "~bootstrap/scss/navbar";
@import "~bootstrap/scss/card";
@import "~bootstrap/scss/breadcrumb";
@import "~bootstrap/scss/pagination";
@import "~bootstrap/scss/badge";
@import "~bootstrap/scss/jumbotron";
@import "~bootstrap/scss/alert";
@import "~bootstrap/scss/progress";
@import "~bootstrap/scss/spinners";
@import "~bootstrap/scss/toasts";
@import "~bootstrap/scss/media";
@import "~bootstrap/scss/list-group";
@import "~bootstrap/scss/close";
@import "~bootstrap/scss/modal";
@import "~bootstrap/scss/tooltip";
@import "~bootstrap/scss/popover";
@import "~bootstrap/scss/carousel";
@import "~bootstrap/scss/utilities";
@import "~bootstrap/scss/print";
